<template>
  <div>
    <div class="l-modal">
      <div class="l-modal_cont">
        <div class="l-visitorCall">
          <VisitorCallModalMember :nameJa="ja" :nameEn="en" :img="img" />

          <div class="l-visitorCall_btn">
            <BtnMedium
              txt="呼び出す"
              link="/visitor_call_modal_call"
            />
            <BtnSmallBack
              txt="担当者一覧に戻る"
              link="/visitor_member_list"
              class="l-visitorCall_btn_back"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import VisitorCallModalMember from "@/components/VisitorCallModalMember.vue";
import BtnMedium from "@/components/BtnMedium.vue";
import BtnSmallBack from "@/components/BtnSmallBack.vue";

export default {
  name: "VisitorCall",
  components: {
    VisitorCallModalMember,
    BtnMedium,
    BtnSmallBack,
  },
  data: () => ({
    ja: "",
    en: "",
    img: "",
  }),
  async mounted() {
    const comp_id = localStorage.getItem("comp_id");
    const memb_id = localStorage.getItem("memb_id");
    const { data } = await axios.get("/companies.json");
    const member = data
      .filter((x) => x.id === comp_id)[0]
      .members.filter((x) => String(x.id) === memb_id)[0];
    this.ja = member.ja;
    this.en = member.en;
    this.img = member.img;

    localStorage.setItem("ja", this.ja);
    localStorage.setItem("en", this.en);
    localStorage.setItem("img", this.img);
  },
};
</script>

<style scoped lang="scss"></style>
